<template>
  <b-list-group class="rounded-0">
    <div v-for="(item, i) in data" :key="i">
      <b-list-group-item
        class="d-flex justify-content-between align-items-center mb-25 p-0 px-1"
        :class="item.statu ? ' border-primary' : ' border-danger'"
      >
        <h6 class="mt-25">{{ item.icerik['tr'].baslik }}</h6>
        <div class="d-flex justify-content-between align-items-center">
          <ul class="unstyled-list list-inline pt-1 pr-2">
            <li v-for="(lang, index) in item.icerik" :key="index" class="ratings-list-item">
              <b-button squared :variant="langColor(item.icerik[index])" size="sm" v-b-tooltip.hover.bottom="`${index}`">
                {{ index }}
              </b-button>
            </li>
          </ul>
          <span class="pr-2">{{ turuTitle(item.turu) }}</span>
          <div>
            <b-button-group>
              <b-button squared size="sm" variant="primary" v-b-tooltip.hover.top="'Alt Kategori'">
                {{ item.children.length }}
              </b-button>
              <b-button
                squared
                size="sm"
                :variant="item.statu ? 'success' : 'danger'"
                v-b-tooltip.hover.top="item.statu ? 'Aktif' : 'Pasif'"
              >
                <feather-icon icon="EyeIcon" v-if="item.statu" />
                <feather-icon icon="EyeOffIcon" v-else />
              </b-button>
              <b-button squared size="sm" variant="warning" v-b-tooltip.hover.top="'Güncelle'" @click="$emit('update', item)">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button squared size="sm" variant="danger" v-b-tooltip.hover.top="'Sil'" @click="handlerRemove(item.k_no)">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-button-group>
          </div>
        </div>
      </b-list-group-item>
      <b-list-group class="rounded-0 pl-2 mb-25" v-for="(child, i) in item.children" :key="i">
        <b-list-group-item
          class="d-flex justify-content-between align-items-center p-0 px-1"
          :class="child.statu ? ' border-warning' : ' border-danger'"
        >
          <h6 class="mt-25">{{ child.icerik['tr'].baslik }}</h6>
          <div class="d-flex justify-content-between align-items-center">
            <ul class="unstyled-list list-inline pt-1 pr-2">
              <li v-for="(lang, index) in child.icerik" :key="index" class="ratings-list-item">
                <b-button squared :variant="langColor(child.icerik[index])" size="sm" v-b-tooltip.hover.bottom="`${index}`">
                  {{ index }}
                </b-button>
              </li>
            </ul>
            <span class="pr-2">{{ turuTitle(child.turu) }}</span>
            <b-button-group>
              <b-button
                squared
                size="sm"
                :variant="child.statu ? 'success' : 'danger'"
                v-b-tooltip.hover.top="child.statu ? 'Aktif' : 'Pasif'"
              >
                <feather-icon icon="EyeIcon" v-if="child.statu" />
                <feather-icon icon="EyeOffIcon" v-else />
              </b-button>
              <b-button squared size="sm" variant="warning" v-b-tooltip.hover.top="'Güncelle'" @click="$emit('update', child)">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button squared size="sm" variant="danger" v-b-tooltip.hover.top="'Sil'" @click="handlerRemove(child.k_no)">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-button-group>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-list-group>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import Swal from 'sweetalert2';
export default {
  components: {
    BCardNormal,
  },
  props: {
    data: {
      type: Array,
      required: false,
    },
  },

  setup(props, context) {
    const toast = useToast();
    const turuTitle = computed(() => {
      const statusText = {
        sayfa: 'Sayfa',
        kategori: 'Kategori',
        urun: 'Ürün',
        blog: 'Blog',
        galeri: 'Galeri',
      };
      return (turu) => statusText[turu];
    });

    const langColor = (value) => {
      return value.baslik != null ? 'flat-success' : 'flat-danger';
    };

    const handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('kategoriSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'CheckSquareIcon',
                    variant: 'danger',
                    text: 'Silme başarılı.',
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
              context.emit('refresh', k_no);
            } else {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'CheckSquareIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
            }
          });
        }
      });
    };
    return { turuTitle, langColor, handlerRemove };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
